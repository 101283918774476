import { Entity } from '../../models/entity.model'
import { QueryStringParams } from '../../models/query-string.model'
import { StoredenProductField } from '../storeden/storeden.model'
import { ProductMappingField } from '../products/product.model'

export enum ChannelKind {
  generic = 'GENERIC',
  storeden = 'STOREDEN',
  shopify = 'SHOPIFY',
}

export enum ChannelQaplaOrigin {
  amazon = 'amazon',
  carrefour_es = 'carrefour_es',
  cdiscount = 'cdiscount',
  ebay = 'ebay',
  ecwid = 'ecwid',
  eprice = 'eprice',
  ibs = 'ibs',
  magento = 'magento',
  magento2 = 'magento2',
  manomano_de = 'manomano_de',
  manomano_es = 'manomano_es',
  manomano_fr = 'manomano_fr',
  manomano_gb = 'manomano_gb',
  manomano_it = 'manomano_it',
  prestashop = 'prestashop',
  privalia_es = 'privalia_es',
  privalia_it = 'privalia_it',
  shopify = 'shopify',
  spartoo_be = 'spartoo_be',
  spartoo_cn = 'spartoo_cn',
  spartoo_de = 'spartoo_de',
  spartoo_es = 'spartoo_es',
  spartoo_fr = 'spartoo_fr',
  spartoo_gb = 'spartoo_gb',
  spartoo_gr = 'spartoo_gr',
  spartoo_it = 'spartoo_it',
  spartoo_nl = 'spartoo_nl',
  spartoo_pl = 'spartoo_pl',
  spartoo_pt = 'spartoo_pt',
  storeden = 'storeden',
  vtex = 'vtex',
  woocommerce = 'woocommerce',
  worten_es = 'worten_es',
  worten_pt = 'worten_pt',
}

export interface Channel extends Entity {
  name: string
  kind: ChannelKind
  catalogCode?: string
  qapla?: ChannelQapla
  storeden?: ChannelStoreden
  shopify?: ChannelShopify
  customSettings?: ChannelSettings
  defaultCarrier?: ChannelDefaultCarrier
  automaticCarrier?: boolean
  payments?: ChannelPayment[]
  carriers?: ChannelCarrier[]
  productIdentifier?: 'SKU' | 'alternateSKU'
  callOn?: ChannelCallOn
  sync?: ChannelSync
  warehouseId?: string
  botId?: string
  integration?: ChannelIntegration
}

export interface ChannelIntegration {
  connectedAt?: string
  status: ChannelIntegrationStatus
}

export type ChannelIntegrationStatus =
  | 'CONNECTED'
  | 'CONNECTING'
  | 'DISCONNECTED'
  | 'ERROR'

export interface ChannelQapla {
  apikey: string
  callOn: 'PACKING' | 'SHIPPING'
  reference: 'rifOrder' | 'orderNumber'
  origin: ChannelQaplaOrigin
}

export interface ChannelStoredenFieldsMapping {
  field: ProductMappingField
  externalField: StoredenProductField
}

export interface ChannelStoredenAttributesMapping {
  _id: string
  externalField: StoredenProductField
}

export interface ChannelStoredenAttributesFiltersMapping {
  _id: string
  externalFilterCode: string
}

export interface ChannelStoredenSync {
  orders: boolean
  catalog: boolean
  productQuantities: boolean
}

export interface ChannelStoreden {
  fieldsMapping: ChannelStoredenFieldsMapping[]
  attributesMapping: ChannelStoredenAttributesMapping[]
  attributesFiltersMapping: ChannelStoredenAttributesFiltersMapping[]
  attributeIdVariantKey?: string
  attributeIdFeatured?: string
  sync: ChannelStoredenSync
}

export interface ChannelShopify {
  /**
   * Target shop name (last domain level in the Shopify URL).
   */
  shopName: string
  /**
   * For which orders we have Shopify notify the user about their fulfillment.
   */
  notifyUser: ChannelShopifyNotification
  /**
   * Declared API version.
   *
   * @default "2022-10"
   */
  apiVersion?: string
  /**
   * @deprecated Private app API key.
   */
  apiKey?: string
  /**
   * @deprecated Private app password.
   */
  password?: string
  /**
   * Custom app (full) access token.
   */
  accessToken?: string
  /**
   * Secret used to verify webhooks.
   */
  sharedSecret: string
  /**
   * Fulfillment location for channel's orders.
   */
  locationId: number
  /**
   * Ignore orders destined to a location that has a different ID than the previous field locationId
   */
  ignoreUnassignedOrders: boolean
}

export type ChannelShopifyNotification =
  | 'ALWAYS'
  | 'NEVER'
  | 'CUSTOMER_ORDERS'
  | 'PICKUP_POINT_ORDERS'

export interface ChannelSettings {
  exchange?: string
  key?: string
  externalId?: string
  url?: string
  storeName?: string
  currency?: string
  locale?: ChannelSettingsLocale
}

export interface ChannelSettingsLocale {
  main: string
  additional: string[]
}

export interface ChannelDefaultCarrier {
  _id?: string
  name?: string
  externalId?: string
}

export interface ChannelPayment {
  _id: string
  externalId: string
}

export interface ChannelCarrier {
  _id: string
  externalId: string
}

export interface ChannelExtPayment {
  code: string
  name: string
}

export interface ChannelCallOn {
  processing: 'PROCESSING' | 'PACKED'
  shipped: 'PACKED' | 'SHIPPED'
}

export interface ChannelSync {
  /**
   * Enables the catalog's products sync between Orderpod and the channel's backend.
   * @default false
   */
  catalog?: boolean
  /**
   * Enables the orders sync between Orderpod and the channel's backend.
   * @default false
   */
  orders?: boolean
  /**
   * Disables gift card orders sync between Orderpod and the channel's backend.
   * @default false
   */
  ignoreGiftCardOrders?: boolean
  /**
   * Enables the products' quantity sync between Orderpod and the channel's backend.
   * @default false
   */
  quantities?: boolean
}

export interface ChannelReference {
  _id: string
  reference?: string
  isActive: boolean
}

export interface ChannelNotificationOptions {
  code: ChannelNotificationCode
  data?: ChannelNotificationData
}

export interface ChannelNotificationData {
  channel?: Channel
}

export type ChannelNotificationCode =
  | 'CHANNEL_INCOMPLETE'
  | 'CHANNEL_SAVED'
  | 'CHANNEL_DELETION'
  | 'CHANNEL_DELETED'
  | 'CHANNEL_CONNECTED'
  | 'CHANNEL_DISCONNECTED'
  | 'SYNC_STARTED'
  | 'STOREDEN_MISSING_MAPPINGS'

export interface ChannelExtData {
  payments?: ChannelExtPayment[]
  shippyProIntegration?: boolean
}

export interface ChannelData extends ChannelExtData {
  channel: Channel
}

export type ChannelSearchField = '_id' | 'name' | 'kind' | 'catalogCode'
export type ChannelSortField = 'name'

export interface ChannelSearchParams
  extends QueryStringParams<ChannelSortField> {
  name?: string
  'name:ne'?: string
  'name:ct'?: string
  kind?: ChannelKind[] | ChannelKind
  catalogCode?: string[] | string
}

export type ChannelTab = 'general' | 'orders' | 'products'
