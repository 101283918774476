import { Injectable, Inject } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { combineLatest, Observable, last, concat, of } from 'rxjs'
import { map } from 'rxjs/operators'
import { paginateArray } from '@evologi/shared/util-toolkit'

import { CrudService } from '../../services/crud.service'
import { SDKConfiguration, SDK_CONFIGURATION } from '../../models/config.model'
import { SDK_SETTINGS } from '../../consts/config.const'
import {
  Product,
  ProductBatchManagement,
  ProductBatchSignature,
  ProductBulkUpdate,
  ProductBulkUpdateResponse,
  ProductExportStockData,
  ProductSearchParams,
  ProductType,
} from './product.model'
import { Page } from '../../models/util.model'
import { difference, uniq } from 'lodash'

const MODEL = 'products'
const VERSION = 'v3'

@Injectable({
  providedIn: 'root',
})
export class ProductsService extends CrudService {
  constructor(
    @Inject(SDK_CONFIGURATION) config: SDKConfiguration,
    http: HttpClient,
  ) {
    super(
      config,
      http,
      `${config.apiUrl}/${SDK_SETTINGS.apiPath}/${VERSION}/${MODEL}`,
      true,
    )
  }

  /**
   * Create a new product
   * @param product - The product to create
   * @returns The observable<Product> to create the product
   */
  create$(product: Product): Observable<Product> {
    return this._create$<Product>(product)
  }

  /**
   * Read a product by ID
   * @param productId - The product ID
   * @returns The observable<Product> for read the product
   */
  read$(productId: string): Observable<Product> {
    return this._read$<Product>(productId)
  }

  /**
   * Update a product by ID
   * @param productId - The product ID
   * @param product - The product body to update
   * @returns The observable<Product> for update the product
   */
  update$(productId: string, product: Product): Observable<Product> {
    return this._update$(productId, { ...product, force: true })
  }

  /**
   * Create or update a product by ID
   * @param productId - The product ID
   * @param product - The product body to update
   * @returns The observable<Product> for update the product
   */
  upsert$(product: Product): Observable<Product> {
    return this._upsert$<Product>(product, product._id)
  }

  /**
   * Delete a product by ID
   * @param productId - The product ID
   * @returns The observable<Product> for delete the product
   */
  delete$(productId: string): Observable<Product> {
    return this._delete$<Product>(productId)
  }

  /**
   * Search products by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<Page<Product>> for search products
   */
  search$(
    params?: ProductSearchParams,
    returnAll = false,
  ): Observable<Page<Product>> {
    return this._search$<Product>(params, returnAll)
  }

  /**
   * List products by params
   * @param params - The search params
   * @param returnAll - the returnAll flag
   * @returns The observable<Product[]> for list products
   */
  list$(
    params?: ProductSearchParams,
    returnAll = false,
  ): Observable<Product[]> {
    return this._list$<Product>(params, returnAll)
  }

  /**
   * Bulk update products
   * @param params - The bulk update params
   * @returns The observable<ProductBulkUpdateResponse> for update products
   */
  bulkUpdate$(
    params: ProductBulkUpdate,
  ): Observable<ProductBulkUpdateResponse> {
    return this.http.post<ProductBulkUpdateResponse>(
      `${this.apiUrl}/bulk-update?async=true`,
      params,
    )
  }

  /**
   * Increase product location
   * @param productId - The product ID
   * @param locationId - The location ID
   * @param params - The increase params
   * @returns The observable<Product> for increase the product location
   */
  increaseLocation$(
    productId: string,
    locationId: string,
    params: {
      quantity: number
      reasonId: string
      notes?: string
      lot?: string
      expirationDate?: string
      serial?: string
    },
  ): Observable<Product> {
    return this.http.post<Product>(
      `${this.apiUrl}/${productId}/locations/${locationId}/increment`,
      params,
    )
  }

  /**
   * Decrease product location
   * @param productId - The product ID
   * @param locationId - The location ID
   * @param params - The decrease params
   * @returns The observable<Product> for decrease the product location
   */
  decreaseLocation$(
    productId: string,
    locationId: string,
    params: {
      quantity: number
      reasonId: string
      notes?: string
      lot?: string
      expirationDate?: string
      serial?: string
    },
  ): Observable<Product> {
    return this.http.post<Product>(
      `${this.apiUrl}/${productId}/locations/${locationId}/decrement`,
      params,
    )
  }

  /**
   * Move a product quantity from a location to another
   * @param productId - The product ID
   * @param sourceLocationId - The source location ID
   * @param targetLocationId - The target location ID
   * @param params - The move params
   * @returns The observable<Product> for move the product
   */
  move$(
    productId: string,
    sourceLocationId: string,
    targetLocationId: string,
    params: {
      quantity: number
      lot?: string
      expirationDate?: string
      serial?: string
    },
  ): Observable<Product> {
    return this.http.post<Product>(
      `${this.apiUrl}/${productId}/move/from/${sourceLocationId}/to/${targetLocationId}`,
      params,
    )
  }

  /**
   * Search historic product quantities by IDs and date
   * @param productIds - The products IDs
   * @param date - The date to search
   * @returns The observable<Record<string, number>> for search historic product quantities
   */
  historicQuantities$(
    productIds: string[],
    warehouseId: string,
    date: string,
  ): Observable<Record<string, number>> {
    const observables$: Observable<Record<string, number>>[] = []

    for (const page of paginateArray(productIds, this.limit)) {
      observables$.push(
        this.http.post<Record<string, number>>(
          `${this.apiUrl}/historic-quantities`,
          {
            productIds: productIds.slice(page.start, page.end),
            date,
            warehouseId,
          },
        ),
      )
    }

    return combineLatest(observables$).pipe(
      map((quantities) =>
        quantities.reduce((acc, quantity) => ({ ...acc, ...quantity }), {}),
      ),
    )
  }

  /**
   * Get products upload url
   * @returns the url to upload products by file
   */
  getUploadProductsUrl(): string {
    return `${this.apiUrl}/blob`
  }

  /**
   * Get products upload url
   * @returns the url to upload products by file
   */
  getOldUploadProductsUrl(): string {
    return `${this.apiUrl}/import/xlsx`
  }

  /**
   * Get products suppliers upload url
   * @returns the url to upload products suppliers by file
   */
  getUploadSuppliersUrl(): string {
    return `${this.apiUrl}/suppliers/import`
  }

  /**
   * Get products suppliers upload template
   * @returns the xlsx file to upload products suppliers
   */
  downloadSuppliersTemplate$(): Observable<Blob> {
    return this.http.get(`${this.apiUrl}/suppliers/import.xlsx`, {
      responseType: 'blob',
    })
  }

  /**
   * Cast a product batch
   * @param productId - The product ID
   * @param settings - The batch management settings
   * @param signature - The batch signature
   * @returns the observable for product batch casting
   */
  castBatch$(
    productId: string,
    settings: ProductBatchManagement,
    signature: ProductBatchSignature,
  ): Observable<Product> {
    return this.http.post<Product>(
      `${this.apiUrl}/${productId}/batch-management/enable`,
      {
        settings,
        signature,
      },
    )
  }

  /**
   * Update a product batch
   * @param productId - The product ID
   * @param locationId - The location ID
   * @param oldSignature - The old batch signature
   * @param newSignature - The new batch signature
   * @returns the observable<Product> for product batch update
   */
  updateBatch$(
    productId: string,
    locationId: string,
    oldSignature: ProductBatchSignature,
    newSignature: ProductBatchSignature,
  ): Observable<Product> {
    return this.http.post<Product>(
      `${this.apiUrl}/${productId}/locations/${locationId}/batches/update-one`,
      {
        oldSignature,
        newSignature,
      },
    )
  }

  /**
   * Split a product batchù
   * @param productId - The product ID
   * @param locationId - The location ID
   * @param oldSignature - The old batch signature
   * @param newSignature - The new batch signature
   * @param quantity - The new batch quantity
   * @returns the observable<Product> for product batch split
   */
  splitBatch$(
    productId: string,
    locationId: string,
    oldSignature: ProductBatchSignature,
    newSignature: ProductBatchSignature,
    quantity: number,
  ): Observable<Product> {
    return this.http.post<Product>(
      `${this.apiUrl}/${productId}/locations/${locationId}/batches/split`,
      {
        oldSignature,
        newSignature,
        quantity,
      },
    )
  }

  /**
   * Product parent link children
   * @param parentId - The product parent ID
   * @param childrenIds - The children products IDs
   * @returns the observable<Product> for children link
   */
  linkChildren$(parentId: string, childrenIds: string[]): Observable<Product> {
    return this.http.post<Product>(`${this.apiUrl}/${parentId}/link-children`, {
      childrenIds,
    })
  }

  /**
   * Product parent unlink children
   * @param parentId - The product parent ID
   * @param childrenIds - The children products IDs
   * @returns the observable<Product> for children unlink
   */
  unlinkChildren$(
    parentId: string,
    childrenIds: string[],
  ): Observable<Product> {
    return this.http.post<Product>(
      `${this.apiUrl}/${parentId}/unlink-children`,
      {
        childrenIds,
      },
    )
  }

  /**
   * Attach a file to a product
   * @param productId - The product ID
   * @param fileId - The file ID
   * @returns the observable<Product> for attach a product file
   */
  attachFile$(productId: string, fileId: string): Observable<Product> {
    return this.http.post<Product>(`${this.apiUrl}/${productId}/files`, {
      _id: fileId,
    })
  }

  /**
   * Attach files to a product
   * @param productId - the product ID
   * @param fileIds - the file IDs
   * @returns the observable<Product> for attach the files to a product
   */
  attachFiles$(productId: string, fileIds: string[]): Observable<Product> {
    return concat(
      ...fileIds.map((fileId) => this.attachFile$(productId, fileId)),
    ).pipe(last())
  }

  /**
   * Detach a file to a product
   * @param productId - The product ID
   * @param fileId - The file ID
   * @returns the observable<Product> for detach a product file
   */
  detachFile$(productId: string, fileId: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/${productId}/files/${fileId}`)
  }

  /**
   * Read a single product by params
   * @param params - The search params
   * @returns the observable<Product> to read a single product
   */
  readOne$(params: ProductSearchParams): Observable<Product> {
    return this._readOne$<Product>(params)
  }

  /**
   * Print the product label
   * @param productId - The product ID
   * @param printerId - The printer ID
   * @param qty - The number of labels to print
   * @returns the observable<void> to print the product label
   */
  printLabel$(
    productId: string,
    printParams: {
      printerId: string
      qty: number
      barcode?: string
    },
  ): Observable<void> {
    return this.http.post<void>(
      `${this.apiUrl}/${productId}/files/label/print`,
      printParams,
    )
  }

  /**
   * Print products labels
   * @param items - the products data to print
   * @param printerId - the printer ID
   * @returns the observable for print products labels
   */
  printLabels$(
    items: { key: string; name: string; qty: number }[],
    printerId: string,
  ): Observable<void[]> {
    return combineLatest(
      items.map((item) =>
        this.printLabel$(item.key, { printerId, qty: item.qty }),
      ),
    )
  }

  /**
   * Download the product label
   * @param productId - The product ID
   * @returns the observable<any> for download the product label
   */
  downloadLabel$(product: Product): Observable<{ blob: Blob; label: string }> {
    return this.http
      .get(`${this.apiUrl}/${product._id}/files/label`, {
        responseType: 'blob',
      })
      .pipe(map((blob) => ({ blob, label: `label-${product.SKU}.pdf` })))
  }

  /**
   * Export product history stock
   * @param exportData - The export params
   * @returns the observable<void> for export products stock
   */
  exportStock$(exportData: ProductExportStockData): Observable<void> {
    return this.http.post<void>(
      `${this.apiUrl}/export/stock-history`,
      exportData,
    )
  }

  /**
   * Check if packaging management is enabled
   * @returns the observable<boolean> for check the packaging management
   */
  isPackagingManagementEnabled$(): Observable<boolean> {
    return this.list$({
      productType: ProductType.package,
      fields: '_id',
      limit: 1,
    }).pipe(map((products) => products.length > 0))
  }

  /**
   * Generate products store
   * @param productIds - the product IDs to load
   * @param products - the products already loaded
   * @param toReload - indicates if must be reloaded
   * @returns the Observable<User[]> as store
   */
  store$(
    productIds: string[],
    products: Product[],
    toReload = false,
  ): Observable<Product[]> {
    productIds = uniq(productIds)

    if (!toReload) {
      productIds = difference(
        productIds,
        products.map((u) => u._id),
      )
    }

    if (productIds.length === 0) {
      return of(products)
    }

    return this.list$({ _id: productIds }).pipe(
      map((prods) => [...products, ...prods]),
    )
  }
}
